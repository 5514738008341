/** @jsx jsx */
import { jsx, Button, Text } from "theme-ui";
import { graphql } from "gatsby";
import { Fragment } from "react";

import {
  BackLink,
  Heading,
  IncludingWorksBy,
  PortableText,
  ProtectedImage,
  Layout,
  Link,
} from "components";

function PublicationPage({ data: { publication } }) {
  const {
    title,
    image,
    details,
    publication_date_site,
    author,
    price,
    pdf_file = {},
    including_works_by,
  } = publication;
  const pdfFile = pdf_file?.asset?.url;

  return (
    <Layout pageTitle={[title, "Publications"]}>
      {({ openContactForm }) => {
        return (
          <Fragment>
            <BackLink to="/publications" sx={{ mb: "s" }}>
              publications
            </BackLink>
            <Heading mb="s">{title}</Heading>

            <div sx={{ mb: "s" }}>
              {publication_date_site && (
                <Text as="p">{publication_date_site}</Text>
              )}
              {author && <Text as="p">Published by: {author}</Text>}
              {price && <Text as="p">Price: {price}</Text>}
            </div>

            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row-reverse"],
                width: "100%",
              }}
            >
              {image?.asset?.gatsbyImageData && (
                <ProtectedImage
                  image={image.asset.gatsbyImageData}
                  alt={title}
                  sx={{
                    maxWidth: [null, "420px", "640px"],
                    ml: [null, "m"],
                    mb: ["s", "m"],
                  }}
                />
              )}

              <div sx={{ flexGrow: 1, flexShrink: 1 }}>
                {details && <PortableText content={details} />}

                {pdfFile && (
                  <Text as="p">
                    <Link href={pdfFile} target="_blank" rel="noreferrer">
                      DOWNLOAD CATALOGUE PDF
                    </Link>
                  </Text>
                )}

                {including_works_by.length > 0 && (
                  <IncludingWorksBy includingWorksBy={including_works_by} />
                )}

                {openContactForm && (
                  <div
                    sx={{
                      mt: "m",
                      pt: "m",
                      borderTop: "solid 1px #ddd",
                    }}
                  >
                    <Button
                      variant="asLink"
                      type="button"
                      onClick={() => openContactForm({ publication })}
                    >
                      Enquire
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        );
      }}
    </Layout>
  );
}

export const query = graphql`
  query Publication($id: String) {
    publication: sanityPublication(_id: { eq: $id }) {
      image {
        asset {
          gatsbyImageData
        }
      }
      details: _rawDetails(resolveReferences: { maxDepth: 10 })
      artist {
        first_name
        last_name
      }
      author
      category {
        category
      }
      price
      publication_date_site
      title
      pdf_file {
        asset {
          url
        }
      }
      including_works_by {
        _id
        first_name
        last_name
      }
    }
  }
`;

export default PublicationPage;
